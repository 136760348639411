<template>
	<div class="footer">
		<div class="top">
			<div class="left">
				<div class="logo">
					<img src="../assets/images/index2022/logo2022.jpg">
				</div>
				<div class="link-list">
					<div class="link" @click="link('lifeBrand')">关于小院</div>
					<div class="link" @click="link('newsList','32,39,29,35,26')">资讯中心</div>
					<div class="link" @click="link('newsList','39')">活动中心</div>
					<div class="link" @click="link('lifeContact')">企业合作</div>
					<div class="link" @click="open('http://chat.xiaoyuan.com.cn/index/index?code=6LXOO%2FvcjCWF0yYFJIXazW00LzJy4Ffuh%2B2%2BPwu7UwMYbKkkv46UYY5mAizTXAnxkHkL0jlSLXlxDU%2BZh4%2BDgEUaD2wZYiwhlJfefRvgsUPJcvslzPsKbcslT38')">在线客服</div>
					<div class="link" @click="open('http://oa.xiaoyuan.com.cn/wui/index.html#/?logintype=1&_key=tm9n11')">办公登录</div>
				</div>
			</div>
			<div class="right">
				<a class="iconfont icon-weibo" href="https://weibo.com/u/5686014371?is_hot=1" target="_blank"
					@mouseenter="mouseenter('weibo')" @mouseout="mouseout('weibo')">
					<img src="../assets/images/weibo.png" alt="" class="weibo animated fadeIn" v-if="showWeibo">
				</a>
				<div class="iconfont icon-weixin" @mouseenter="mouseenter('weixin')" @mouseout="mouseout('weixin')">
					<img src="../assets/images/qrcode.png" alt="" class="qrcode animated fadeIn" v-if="showWeixin">
				</div>
				<div class="iconfont icon-xiaochengxu" @mouseenter="mouseenter('xiaochengxu')"
					@mouseout="mouseout('xiaochengxu')">
					<img src="../assets/images/xiaochengxu.png" alt="" class="xiaochengxu animated fadeIn"
						v-if="showXiaochengxu">
				</div>
			</div>
		</div>

		<div class="bottom">
			<div class="copyright">
				Copyright © {{year}} 西安小院科技股份有限公司 版权所有
			</div>
			<div class="footer-record">
				<a href="https://beian.miit.gov.cn/" target="_blank" class="ga-data">陕ICP备18018083号</a>
				<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011302001720" target="_blank" class="ga-data"><img src="../assets/images/public-record.png"/>陕公网安备 61011302001720号</a>
				
				<!-- <p class="ad-desc">本网站直接或间接向消费者推销商品或者服务的商业宣传均属于“广告”（包装及参数、售后保障等商品信息除外）</p> -->
			</div>
		</div>
		<!-- <BackTop></BackTop> -->
	</div>
</template>

<script>
	import BackTop from "@/components/backtop.vue"
	import utils from '@/common/utils'
	export default {
		name: 'Footer',
		data() {
			return {
				showWeixin: false,
				showWeibo: false,
				showXiaochengxu: false,
			}
		},
		components: {
			BackTop,
		},
		computed: {
			year: function() {
				return utils.date('Y')
			},
		},
		methods: {
			mouseenter(e) {
				if (e == 'weixin') {
					this.showWeixin = true;
				} else if (e == 'weibo') {
					this.showWeibo = true;
				} else if (e == 'xiaochengxu') {
					this.showXiaochengxu = true;
				}
			},
			mouseout(e) {
				if (e == 'weixin') {
					this.showWeixin = false;
				} else if (e == 'weibo') {
					this.showWeibo = false;
				} else if (e == 'xiaochengxu') {
					this.showXiaochengxu = false;
				}

			},
			link(pathName,e) {
				this.$router.push({
					name: pathName,
					params: {
						paramsName: e
					}
				});
			},
			open(url) {
				window.open(url);
			},
		},
	}
</script>

<style lang="scss" scoped>
	.animated {
		animation-duration: .5s;
	}

	.footer-record {
		display: flex;
		margin-top: 8px;
		line-height: 22px;
		img{
			margin-left: 16px;
		}
	}

	.ad-desc {
		margin-left: 13px;
	}

	.footer {
		width: 100%;
		background-color: #fff;
		color: #262626;
		font-size: 12px;
		padding: 57px 64px 46px 64px;
		box-sizing: border-box;
	}

	.top {
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 2px solid #B5B5B5;
	}

	.footer a {
		color: #616466;
		text-decoration: none;
	}


	.footer .left {
		display: flex;
		align-items: center;
	}

	.footer .left .logo img {
		width: 21px;
		height: 21px;
	}

	.footer .left .link-list {
		display: flex;
	}

	.footer .left .link-list .link {
		margin-left: 26px;
		cursor: pointer;
	}

	.footer .bottom {
		color: #B5B5B5;
		font-size: 12px;
		line-height: 12px;
		padding-top: 22px;
	}

	.footer .bottom a {
		color: #B5B5B5;
	}

	.footer .right {
		display: flex;
		margin-left: auto;
	}

	.footer .right a {
		color: #9fa3a6;
	}


	.footer .right .iconfont {
		font-size: 18px;
		color: #000000;
	}

	.footer .right .icon-weibo {
		margin-right: 22px;
		position: relative;
	}

	.footer .right .icon-xiaochengxu {
		font-size: 16px;
		position: relative;
	}

	.footer .right .icon-weixin {
		margin-right: 22px;
		position: relative;
	}

	.footer .right .icon-weixin .qrcode {
		width: 126.7px;
		height: 139.3px;
		position: absolute;
		bottom: 50px;
		left: -58px;
	}

	.footer .right .icon-weibo .weibo {
		width: 126.7px;
		height: 139.3px;
		position: absolute;
		bottom: 50px;
		left: -58px;
	}

	.footer .right .icon-xiaochengxu .xiaochengxu {
		width: 126.7px;
		height: 139.3px;
		position: absolute;
		bottom: 50px;
		left: -58px;
	}

	.footer .right .function-oa {
		font-size: 12px;
		margin-left: 8px;
	}
</style>
