<template>
  <div class="suspend" v-show="showTop">
    <div class="service" @click="service">
      <i class="iconfont icon-zhuanshukefu"></i>
    </div>
    <div class="gotop" @click="toTop">
      <i class="iconfont icon-top"></i>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'BackTop',
  data () {
    return {
      scrollTop: 0,
      time: 0,
      dParams: 20,
      scrollState: 0
    }
  },
  computed: {
    ...mapState(['customer_service']),
    showTop: function () {
      let value = this.scrollTop > 500 ? true : false;
      return value;
    },
  },
  mounted () {
    window.addEventListener('scroll', this.getScrollTop);
  },
  methods: {
    toTop (e) {
      if (this.scrollState) {
        return;
      }
      this.scrollState = 1;
      e.preventDefault();
      let _this = this;
      this.time = setInterval(function () { _this.gotoTop(_this.scrollTop - _this.dParams) }, 10);
    },
    gotoTop (distance) {
      this.dParams += 20;
      distance = distance > 0 ? distance : 0;
      document.documentElement.scrollTop = document.body.scrollTop = window.pageYOffset = distance;
      if (this.scrollTop < 10) {
        clearInterval(this.time);
        this.dParams = 20;
        this.scrollState = 0;
      }
    },
    getScrollTop () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    service () {
      if (this.customer_service) {
        window.open(this.customer_service.url);
      } else {
        window.open("http://chat.xiaoyuan.com.cn/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=admin&groupid=0");
      }
    }
  },

}
</script>
<style scoped>
.suspend {
  position: fixed;
  right: 100px;
  bottom: 160px;
  width: 50px;
  height: 92px;
  text-align: center;
  color: #fff;
  z-index: 999;
}
.suspend .iconfont {
  font-size: 24px;
}
.suspend .service {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 100%;
  cursor: pointer;
}
.suspend .service:hover {
  background-color: rgba(50, 50, 50, 0.4);
}
.suspend .gotop {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: rgba(50, 50, 50, 0.3);
  border-radius: 100%;
  margin-top: 10px;
  cursor: pointer;
}
.suspend .gotop:hover {
  background-color: rgba(50, 50, 50, 0.4);
}
</style>